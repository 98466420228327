import React from 'react';
import './App.css'
import SubnetVisualiser from './Components/SubnetVisualiser';

function App() {

  return (
    <SubnetVisualiser className='App' />
  );
}

export default App;
